
import { getAPI } from "@/api/axios-base";
import { notifyInfo } from "@/components/Notification";
import Vue from "vue";

export default Vue.extend({
  name: "note-details",
  props: {
    value: Boolean,
    details: Object,
  },
  data() {
    return {
      dialog: false,
      createCommentForm: false,
      comment: "",
      loading: false,
    };
  },
  methods: {
    closeDialog() {
      this.createCommentForm = false;
      this.$emit("input", false);
    },
    dateTime(dateISOString: string): string {
      const date = new Date(dateISOString);

      return date.toLocaleString();
    },
    createComment() {
      this.loading = true;

      if (this.details) {
        getAPI
          .post("/patient/createComment", {
            details: this.comment,
            noteUuid: this.details.uuid,
          })
          .then(() => {
            this.loading = false;
            this.comment = "";
            notifyInfo("Comment sended");
            this.$emit("comment-created");
          });
      }
    },
  },
});
